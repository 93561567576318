import { fetchQuery } from "utils/relay";
import { emptyProxyObject } from "utils/utils";
import { Action, Thunk, thunk, action } from "easy-peasy";

export interface SessionModel {
  offerPriceResponse: any;
  searchData: any;
  ancillariesData: any;
  ancillariesRequotedTempTotalPayable: any;
  selectedFlightData: any;
  selectedSeats: any[];
  seatsOrderQuoteTotalAmount: any;
  seatAction: any;
  airlineServiceConfigs: any[];
  clearOfferPriceResponse: Action<SessionModel>;
  clearAncillariesData: Action<SessionModel>;
  clearSearchData: Action<SessionModel>;
  clearSelectedFlightData: Action<SessionModel>;
  clearSelectedSeats: Action<SessionModel>;
  setOfferPriceResponse: Action<SessionModel, any>;
  setAncillariesData: Action<SessionModel, any>;
  setAncillariesRequotedTempTotalPayable: Action<SessionModel, any>;
  setSearchData: Action<SessionModel, any>;
  setSelectedFlightData: Action<SessionModel, any>;
  setSelectedSeats: Action<SessionModel, any[]>;
  setSeatsOrderQuoteTotalAmount: Action<SessionModel, any>;
  setSeatAction: Action<SessionModel, any>;
  fetchOfferPriceResponse: Thunk<SessionModel, any>;
  fetchAncillariesData: Thunk<SessionModel, any>;
  fetchSearchData: Thunk<SessionModel, any>;
  fetchSelectedFlightData: Thunk<SessionModel, any>;
  fetchSelectedSeats: Thunk<SessionModel, any>;
  clearSession: Action<SessionModel>;
  setAirlineServiceConfigs: Action<SessionModel, any[]>;
  clearAirlineServiceConfigs: Action<SessionModel>;
  clearAncillariesRequotedTempTotalPayable: Action<SessionModel>;
  clearSeatsOrderQuoteTotalAmount: Action<SessionModel>;
  clearSeatAction: Action<SessionModel>;
}

const SessionStore: SessionModel = {
  offerPriceResponse: emptyProxyObject,
  searchData: emptyProxyObject,
  selectedFlightData: emptyProxyObject,
  selectedSeats: [],
  seatsOrderQuoteTotalAmount: null,
  seatAction: null,
  ancillariesData: emptyProxyObject,
  ancillariesRequotedTempTotalPayable: emptyProxyObject,
  airlineServiceConfigs: [],

  clearOfferPriceResponse: action((state) => {
    state.offerPriceResponse = emptyProxyObject;
  }),

  clearAncillariesData: action((state) => {
    state.ancillariesData = emptyProxyObject;
  }),

  clearSearchData: action((state) => {
    state.searchData = emptyProxyObject;
  }),

  clearSelectedFlightData: action((state) => {
    state.selectedFlightData = emptyProxyObject;
  }),

  clearSelectedSeats: action((state) => {
    state.selectedSeats = [];
  }),

  clearAirlineServiceConfigs: action((state) => {
    state.airlineServiceConfigs = [];
  }),

  clearAncillariesRequotedTempTotalPayable: action((state) => {
    state.ancillariesRequotedTempTotalPayable = emptyProxyObject;
  }),

  clearSeatsOrderQuoteTotalAmount: action((state) => {
    state.seatsOrderQuoteTotalAmount = null;
  }),

  clearSeatAction: action((state) => {
    state.seatAction = null;
  }),

  setOfferPriceResponse: action((state, item) => {
    state.offerPriceResponse = item;
  }),

  setAncillariesData: action((state, item) => {
    state.ancillariesData = item;
  }),

  setAncillariesRequotedTempTotalPayable: action((state, item) => {
    state.ancillariesRequotedTempTotalPayable = item;
  }),

  setSearchData: action((state, item) => {
    state.searchData = item;
  }),

  setSelectedFlightData: action((state, item) => {
    state.selectedFlightData = item;
  }),

  setSelectedSeats: action((state, item) => {
    state.selectedSeats = item;
  }),

  setSeatsOrderQuoteTotalAmount: action((state, item) => {
    state.seatsOrderQuoteTotalAmount = item;
  }),

  setSeatAction: action((state, item) => {
    state.seatAction = item;
  }),

  setAirlineServiceConfigs: action((state, item) => {
    state.airlineServiceConfigs = item;
  }),

  fetchOfferPriceResponse: thunk((actions, { query, variables }) => {
    actions.clearOfferPriceResponse();
    return fetchQuery(query, variables).then((itemWrapper: any) => {
      const key = Object.keys(itemWrapper)[0];
      actions.setOfferPriceResponse(itemWrapper[key]);
    });
  }),

  fetchSearchData: thunk((actions, { query, variables }) => {
    actions.clearSearchData();
    return fetchQuery(query, variables).then((itemWrapper: any) => {
      const key = Object.keys(itemWrapper)[0];
      actions.setSearchData(itemWrapper[key]);
    });
  }),

  fetchSelectedFlightData: thunk((actions, { query, variables }) => {
    actions.clearSelectedFlightData();
    return fetchQuery(query, variables).then((itemWrapper: any) => {
      const key = Object.keys(itemWrapper)[0];
      actions.setSelectedFlightData(itemWrapper[key]);
    });
  }),

  fetchSelectedSeats: thunk((actions, { query, variables }) => {
    actions.clearSelectedSeats();
    return fetchQuery(query, variables).then((itemWrapper: any) => {
      const key = Object.keys(itemWrapper)[0];
      actions.setSelectedSeats(itemWrapper[key]);
    });
  }),

  fetchAncillariesData: thunk((actions, { query, variables }) => {
    actions.clearAncillariesData();
    return fetchQuery(query, variables).then((itemWrapper: any) => {
      const key = Object.keys(itemWrapper)[0];
      actions.setAncillariesData(itemWrapper[key]);
    });
  }),

  clearSession: action((state) => {
    state.offerPriceResponse = emptyProxyObject;
    state.ancillariesData = emptyProxyObject;
    state.selectedFlightData = emptyProxyObject;
    state.selectedSeats = [];
  }),
};

export default SessionStore;
