import { getEnv } from "./utils";

const USER = "user";
const DEFAULT_IATA_NUMBER = "defaultIataNumber";
const DEFAULT_IATA_ID = "defaultIataID";
const DEFAULT_IATA_CURRENCY = "defaultIataCurrency";
const IATA_LIST = "iataList";
const SECOND = 1000;
const MINUTE = SECOND * 60;
const TOKEN_TTL = MINUTE * 19;
const IDLE_TIMEOUT_MIN = MINUTE * 10;
const PASSWORD_STRENGTH = {
  MIN_LENGTH: 8,
  MIN_UPPERCASE: 1,
  MIN_LOWERCASE: 1,
  MIN_SPECIALCHARACTER: 1,
  MIN_NUMERIC: 1,
};
const EMAIL_PLACEHOLDER = "Enter an email address";
const VERIFICATION_CODE_PLACEHOLDER = "123456";
const PASSWORD_PLACEHOLDER = "********";
const FIRSTNAME_PLACEHOLDER = "Enter first name";
const LASTNAME_PLACEHOLDER = "Enter last name";
const PHONE_PLACEHOLDER = "Enter phone number";
const TRIP_TYPE = ["Oneway", "Return", "Multicity"];
const FLIGHT_CLASS = ["Economy", "Business", "First/Premium"];
const FLIGHT_DIRECTION = ["Forward", "Return", "Multi", "Multi-right"];
const FLIGHT_DETAILS_TAB = ["Segment", "Fare Details", "Fare Rule"];
const FLIGHT_CLASS_MAPPING = [
  // { class: "All", key: 7 },
  { class: "Economy", key: 5 },
  { class: "Business", key: 2 },
  { class: "First/Premium", key: 1 },
];
const PASSENGERS_PTC: any = {
  ADT: "Adult(s)",
  CHD: "Children",
  INF: "Infant",
};

const PTC: any = {
  ADT: "Adult",
  CHD: "Child",
  INF: "Infant",
};

const ENV_MAPPER = {
  development: "dev",
  staging: "staging",
  test: "test",
  uat: "uat",
  production: "v3",
  qa: "qa",
  local: "local",
  integration: "intg",
};

const APP_PERMISSION_MAPPER: any = {
  account: "app.account.view",
  workbench: "app.workbench.view",
  admin: "nf.app.platform.view",
  inventory: "app.inventory.view",
  userEdit: "users.self.manage",
  travelOrdersView: "travel.orders.view",
  travelOrdersManage: "travel.orders.manage",
  selfOrdersView: "travel.self.orders.view",
  selfOrdersBookedManage: "travel.self.orders.booked.manage",
  selfOrdersTicketedManage: "travel.self.orders.paid.manage",
  passengersView: "travel.passengers.view",
  passengersManage: "travel.passengers.manage",
  transactionManage: "travel.transactions.manage",
  transactionView: "travel.transactions.view",
  downloadTrace: "users.txns.manage",
  ordersCreate: "travel.orders.create",
  ordersIssueTicket: "travel.orders.issueTicket",
  ordersCancelTickets: "travel.orders.cancelTickets",
};

const SEAT_CHARACTERISTICS: any = [
  {
    code: "2",
    desc: "Leg rest available",
  },
  {
    code: "AB",
    desc: "Seat adjacent to bar",
  },
  {
    code: "AS",
    desc: "Individual airphone",
  },
  {
    code: "B",
    desc: "Seat with bassinet facility",
  },
  {
    code: "E",
    desc: "Exit and emergency exit",
  },
  {
    code: "EC",
    desc: "Electronic connection for lap top or FAX machine",
  },
  {
    code: "I",
    desc: "Seat suitable for adult with an infant",
  },
  {
    code: "IE",
    desc: "Seat not suitable for child",
  },
  {
    code: "J",
    desc: "Rear facing seat",
  },
  {
    code: "L",
    desc: "Leg space seat",
  },
  {
    code: "Q",
    desc: "Seat in a quiet zone",
  },
  {
    code: "U",
    desc: "Seat suitable for unaccompanied minors",
  },
  {
    code: "1A",
    desc: "Seat not allowed for infant",
  },
  {
    code: "1B",
    desc: "Seat not allowed for medical",
  },
  {
    code: "1C",
    desc: "Seat not allowed for unaccompanied minor",
  },
];

const PAYMENT_OPTIONS = [
  {
    type: "Invoice/Cash",
    fields: [
      {
        key: "FOPDetail",
        label: "Notes",
      },
    ],
  },
  {
    type: "Credit",
    fields: [
      {
        key: "FOPDetail",
        label: "Customer Account",
      },
    ],
  },
  {
    type: "Card",
    fields: [
      {
        key: "FOPDetail",
        label: "Authorisation Code",
      },
    ],
  },
  {
    type: "Cheque",
    fields: [
      {
        key: "FOPDetail",
        label: "Bank/Check Name",
      },
      // {
      //     key: "checkNumber",
      //     label: "Check Number",
      // },
    ],
  },
  {
    type: "Bank Transfer",
    fields: [
      {
        key: "FOPDetail",
        label: "Transfer Reference",
      },
    ],
  },
];

const FLIGHT_OFFER_TYPES = ["CombinationOffers", "FlightOffers"];
const FLIGHT_CONNECTIONS = ["Direct Flights", "1 Stop", "2 Stop"];
const FLIGT_TIMINGS = [
  {
    key: "Before 6am",
    hours: ["00:00", "06:00"],
  },
  {
    key: "6am-12pm",
    hours: ["06:00", "12:00"],
  },
  {
    key: "12pm-6pm",
    hours: ["12:00", "18:00"],
  },
  {
    key: "After 6pm",
    hours: ["18:00", "24:00"],
  },
];

const NOT_AVAILABLE_SEATS = ["R", "T", "U", "O"];
// const NOT_AVAILABLE_SEATS = ["2", "3", "D", "H", "I", "N", "O", "Q", "R", "S", "U", "V", "Z"];
const NON_SEAT_COMPONENTS = [
  "28",
  "AR",
  "BA",
  "C",
  "CL",
  "D",
  "E",
  "EX",
  "GF",
  "GN",
  "LA",
  "LG",
  "PC",
  "SO",
  "ST",
  "TA",
  "71",
];
const NON_SEAT_STATUS = ["N", "U"];
const FLIGT_TIMINGS_MAPPER: any = {
  "Before 6am": ["00:00", "06:00"],
  "6am-12pm": ["06:00", "12:00"],
  "12pm-6pm": ["12:00", "18:00"],
  "After 6pm": ["18:00", "24:00"],
};
const ORDER_REPRICED_OFFERTYPE = ["ReShopOffer", "RePricedOffer"];
// const SEAT_SELECTION_ORDER_STATUS = ["Any", "Booked", "Ticketed"];
const SEAT_SELECTION_ORDER_STATUS = [
  "Any",
  "Booked",
  "Ticketed",
  "Prebook",
  "None",
];

// const PAYMENT_LABELS: any = {
//   "1": "Customer account",
//   "2": "Notes",
//   "3": "Authorisation code",
//   "4": "Bank/Cheque number",
//   "5": "Transfer reference",
// };
const PAYMENT_LABELS: any = {
  "1": "Customer Account Code",
  "2": "Cash Account Code",
  "3": "Backoffice Account Code",
  "4": "Backoffice Account Code",
  "5": "Transfer reference",
};

const AUTH_TOKEN =
  getEnv() !== ENV_MAPPER.production ? "authToken" : "ssoToken";

const RESHOP_MODE = ["ReplaceAll", "ReplacePartial"];

const TICKET_MAPPER = ["702", "INF", "T"];

const REFRESH_TOKEN = "refreshToken";

// const SUBSCRIPTION_ID = "SUBSCRIPTION_ID";
// const SUBSCRIPTION_ID = "8dfb616f-ee96-40ee-bbac-1f1817f662e6"; //SQ devqa
const SUBSCRIPTION_ID = "1fe15111-1f74-4fb1-ab27-7395b483155e"; //EK devqa
// const SUBSCRIPTION_ID = "d4ec96a8-1a3f-4694-a61a-02cb1174327e"; //EK local

const SHARED = "SHARED";
const OWNED = "OWNED";
const SUBAGENCY = "SUBAGENCY";
const NOLONGER = "NOLONGER";
const BAGGAGE = "BAGGAGE";
const PENALTY_CODE = "PENF";
// const INFANT_AGE_FOR_CHILD_WARNING =
//   "Infant age entered for child passenger. Please make sure you have entered the correct details";
const INFANT_AGE_FOR_CHILD_WARNING =
  "Please make sure you have entered the correct DOB details for child passenger";
const ADULT_AGE_NOT_RESTRICTED_WARNING =
  "Please make sure you have entered the correct DOB details for adult passenger";
const LI_OWNER_CODE = "NF";
const IS_LOCAL_INVENTORY_SUBSCRIPTION = "isLocalInventorySubscription";
const TICKET_COUPONS = {
  TICKETED: "T",
  EXCHANGED: "E",
  BLOWN: "B",
};
const AIRSHOPPING_FLIGHT_CARD_OWNERCODE_KEY = "airshoppingFlightCardOwnerCode";

const PILL_THEME: any = {
  1: {
    primary: "#2d9263",
    secondary: "#f3fbf7",
  },
  2: {
    primary: "#7803af",
    secondary: "#fbf2ff",
  },
  3: {
    primary: "#13a1c0",
    secondary: "#e7f9fd",
  },
  4: {
    primary: "#f2994a",
    secondary: "#fcf3e9",
  },
};
const DIFFERENTIAL_TYPE_CODE = "REFUND";
const RESIDUAL_SERVICE_VALUE = "RESIDUAL";
const TK_OWNER = "TK";

const CMS_ITEMS = {
  CARD_TYPES: "card_types",
};

const CMS_PARAMS = {
  card_types: {
    fields: "name,brand_code,id,logo",
    deep: "*",
  },
};

export {
  USER,
  AUTH_TOKEN,
  DEFAULT_IATA_NUMBER,
  DEFAULT_IATA_ID,
  DEFAULT_IATA_CURRENCY,
  IATA_LIST,
  TOKEN_TTL,
  IDLE_TIMEOUT_MIN,
  PASSWORD_STRENGTH,
  EMAIL_PLACEHOLDER,
  VERIFICATION_CODE_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
  FIRSTNAME_PLACEHOLDER,
  LASTNAME_PLACEHOLDER,
  PHONE_PLACEHOLDER,
  TRIP_TYPE,
  FLIGHT_CLASS,
  FLIGHT_DIRECTION,
  FLIGHT_DETAILS_TAB,
  FLIGHT_CLASS_MAPPING,
  PASSENGERS_PTC,
  SEAT_CHARACTERISTICS,
  PAYMENT_OPTIONS,
  FLIGHT_OFFER_TYPES,
  FLIGHT_CONNECTIONS,
  FLIGT_TIMINGS,
  APP_PERMISSION_MAPPER,
  NOT_AVAILABLE_SEATS,
  NON_SEAT_COMPONENTS,
  ENV_MAPPER,
  FLIGT_TIMINGS_MAPPER,
  ORDER_REPRICED_OFFERTYPE,
  SEAT_SELECTION_ORDER_STATUS,
  PAYMENT_LABELS,
  NON_SEAT_STATUS,
  RESHOP_MODE,
  TICKET_MAPPER,
  REFRESH_TOKEN,
  SUBSCRIPTION_ID,
  SHARED,
  OWNED,
  SUBAGENCY,
  NOLONGER,
  PTC,
  BAGGAGE,
  PENALTY_CODE,
  INFANT_AGE_FOR_CHILD_WARNING,
  ADULT_AGE_NOT_RESTRICTED_WARNING,
  PILL_THEME,
  LI_OWNER_CODE,
  IS_LOCAL_INVENTORY_SUBSCRIPTION,
  TICKET_COUPONS,
  AIRSHOPPING_FLIGHT_CARD_OWNERCODE_KEY,
  DIFFERENTIAL_TYPE_CODE,
  RESIDUAL_SERVICE_VALUE,
  TK_OWNER,
  CMS_ITEMS,
  CMS_PARAMS,
};
