import { persist } from "easy-peasy";
import AuthStore, { AuthModel } from "store/auth";
import ItemStore, { ItemModel } from "store/item";
import AirportStore, { IAirportModel } from "store/airport";
import SessionStore, { SessionModel } from "store/session";
import OrderResponseStore, { OrderResponseModel } from "store/orderResponse";
import CmsContentStore, { CmsContentModel } from "store/cmsContent";

export interface StoreModel {
  auth: AuthModel;
  item: ItemModel;
  session: SessionModel;
  airports: IAirportModel;
  response: OrderResponseModel;
  cmsContent: CmsContentModel;
}

const Store: StoreModel = {
  auth: AuthStore,
  item: ItemStore,
  airports: persist(AirportStore, { storage: "localStorage" }),
  session: persist(SessionStore),
  response: persist(OrderResponseStore),
  cmsContent: CmsContentStore,
};

export default Store;
