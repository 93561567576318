import { Action, action } from "easy-peasy";
import { emptyProxyObject } from "utils/utils";

export interface OrderResponseModel {
  orderResponse: any;
  clearOrderResponse: Action<OrderResponseModel>;
  setOrderResponse: Action<OrderResponseModel, any>;
  orderPaymentSplit: any;
  clearOrderPaymentSplit: Action<OrderResponseModel>;
  setOrderPaymentSplit: Action<OrderResponseModel, any>;
}

const OrderResponseStore: OrderResponseModel = {
  orderResponse: emptyProxyObject,

  clearOrderResponse: action((state) => {
    state.orderResponse = emptyProxyObject;
  }),

  setOrderResponse: action((state, item) => {
    state.orderResponse = item;
  }),

  orderPaymentSplit: emptyProxyObject,

  clearOrderPaymentSplit: action((state) => {
    state.orderPaymentSplit = emptyProxyObject;
  }),

  setOrderPaymentSplit: action((state, item) => {
    state.orderPaymentSplit = item;
  }),
};

export default OrderResponseStore;
