import React from "react";

const Loader = (props?: any) => {
  const { hght, wdth, poston }: any = props;
  return (
    <div
      style={{
        width: wdth || "100vw",
        height: hght || "100vh",
        position: poston || "fixed",
        zIndex: 11,
        left: 0,
        top: 0,
        background: "rgba(255,255,255,0.7)",
      }}
    >
      <div className="loader-container">
        <div className="loader">
          <div className="loader-logo"></div>
          <div className="loader-logo"></div>
          <div className="loader-logo"></div>
          <div className="loader-logo"></div>
          <div className="loader-logo"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
